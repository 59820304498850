import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify, {
  options: {
    customProperties: true
  },
})

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#97BF5D',
        secondary: '#B5D64D',
        anchor: '#689F38',
        red: '#DC3B50',
        lightblue: '#7EB5E3',
        orange: '#EDA340',
        gray: '#757575',
        black: '#212121'

      },
    },
  },
})

export default vuetify
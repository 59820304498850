import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from '../views/404Page.vue';

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import( '../views/Home.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import( '../views/Account.vue')
  },
  {
    path: '/ads',
    name: 'Ads',
    component: () => import( '../views/Ads.vue')
  },
  {
    path: '/camps',
    name: 'Camps',
    component: () => import( '../views/Camps.vue')
  },
  {
    path: '/parent',
    name: 'Parent',
    component: () => import( '../views/ParentDashboard.vue')
  },
  {
    path: '/resetpassword/:code',
    name: 'ResetPassword',
    component: () => import( '../views/ResetPassword.vue')
  },
  {
    path: '/camp',
    name: 'Camp',
    component: () => import( '../components/Camp.vue')
  },
  {
    path: '/camp/session/edit/:sessionId',
    name: 'EditSessionForm',
    component: () => import( '../components/CampDashboard/EditSessionForm.vue')
  },
  {
    path: '/camp/sessions/add',
    name: 'MultiSessionForm',
    component: () => import( '../components/CampDashboard/MultiSessionForm.vue')
  },
  {
    path: '/camp/sessions/review',
    name: 'ReviewSessions',
    component: () => import( '../components/CampDashboard/ReviewSessions.vue')
  },
  {
    path: '/managecamp',
    name: 'ManageCamp',
    component: () => import( '../views/ManageCamp.vue')
  },
  {
    path: '/map',
    name: 'Map',
    component: () => import( '../views/Map.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import( '../views/About.vue')
  },
  {
    path: '/manage',
    name: 'About',
    component: () => import( '../views/Manage.vue')
  },
  {
    path: '*',
    name: 'Not Found',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    t: '',
    campId: null,
    campName: null,
    campLogo: '',
    campURL: '',
    camp: {},
    categories: [],
    mutliSessionDateObj: {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().add(1, 'month').format('YYYY-MM-DD'),
      sessionDays: "",
      startDay: "Monday"
    },
    multiSessionStartDate: '',
    multiSessionEndDate: '',
    sessionMeetingDays: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    },
    userId: '',
    loggedIn: false,
    sessionsToCreate: [],
    reviewFormData:  {
      beforeCare: false,
      afterCare: false,
      bus: false,
      overnight: false,
      categories: [],
      daysMeet: ""
    },
    mainCamp: {}
  },
  mutations: {
    updateMutliSessionDateObj (state, newObj) {
      this.state.mutliSessionDateObj = {
        startDate: newObj.startDate,
        endDate: newObj.endDate,
        sessionDays: newObj.sessionDays,
        startDay: newObj.startDay
      }
    },
    updateCampId (state, newCampId) {
      state.campId = newCampId
    },
    updateCamp (state, newCamp) {
      this.state.camp = newCamp
    },
    updateMultiSessionStartDate (state, payload) {
      state.multiSessionStartDate = payload.newStartDate
    },
    updateMultiSessionEndDate (state, payload) {
      this.state.multiSessionEndDate = payload.newEndDate
    },
    updateSessionMeetingDays(state, payload) {
      this.$state.sessionMeetingDays = payload.sessionMeetingDays
    },
    updateT(state, payload) {
      state.t = payload
    },
    updateCategories(state, payload) {
      state.categories = payload
    },
    updateUserId (state, userId) {
      state.userId = userId
    },
    updateCampName (state, campName) {
      state.campName = campName
    },
    updateLoggedIn (state, loggedIn) {
      state.loggedIn = loggedIn
      state.camp = {},
      state.campId = null
      state.userId = ""
      state.campName = ""
    },
    updateSessionsToCreate (state, sessions) {
      state.sessionsToCreate = sessions
    },
    updaterReviewFormData(state, reviewFormData) {
      state.reviewFormData = reviewFormData
    },
    mainCamp(state, camp) {
      state.mainCamp = camp
    }
  }
})
export default store;
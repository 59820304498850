import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store.js'
import Cookies from 'js-cookie'
import Axios from 'axios'
import * as VueGoogleMaps from 'vue2-google-maps'

const RESOURCE_NAME = process.env.VUE_APP_BASE_URL;
Vue.config.productionTip = false
Vue.use(require('vue-moment'));
Vue.use(require('vue-cookies'));

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA81UimphUHOOCVGec5l0cLHCbxEtE5AmA',
    libraries: 'places',
  }
});

// let route = window.location.href.split('/')[window.location.href.split('/').length - 1]
new Promise((resolve) => {
  new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
  let jwt = Cookies.get('cit')
  let rft = Cookies.get('rft')
  if(rft && jwt) {
    Axios.post(RESOURCE_NAME+`/login/refresh`, {}, {
      headers: {
        'Authorization': `Bearer ${jwt}`,
        'refreshtoken' : rft,
      }
    })
    .then(t => {
      Cookies.set('cit', t.data.split('|')[1], { expires: 1, path: '' })
      Cookies.set('rft', t.data.split('|')[0], { expires: 1, path: '' })
      jwt = t.data.split('|')[1]
      store.commit('updateLoggedIn', true)
      return t
    })
    .then(() => {
      let newJwt = Cookies.get('cit')
      let newRft = Cookies.get('rft')
      Axios.get(RESOURCE_NAME+`/login/user`, {
        headers: {
          'Authorization': `Bearer ${newJwt}`,
          'Content-Type': 'application/json',
          'refreshtoken': `${newRft}`
        }
      })
      .then(user =>{
        resolve(user)
      })
    })
    .catch((error) => {
      console.log("error", error)
      router.push(process.env.BASE_URL)
    })
  }else {
    // route = '/'
    resolve("need to login")
  }
}).then((user) => {
  if(user !== "need to login"){
    store.commit('updateLoggedIn', true)
    // store.commit('updateUserId', user[0].data.id)
    store.commit('updateCampId', user.data.campId)
  }
  // router.push(route)
})
// .then(() => {
//   let jwt = Cookies.get('cit')
//   let rft = Cookies.get('rft')
//   Axios.post(RESOURCE_NAME+'/camp', {}, {
//     headers: {
//       'Authorization': `Bearer ${jwt}`,
//       'refreshtoken' : rft,
//     }
//   }).then((camps) => {
//     console.log("got camps", camps)
//   })
// })
  
    